import React from "react";
import PropTypes from "prop-types";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const FaqGrid = ({ gridItems, filter, items }) => (
  <div className="accordion">
    {filter === "all"
      ? gridItems &&
        gridItems
          .reverse()
          .slice(0, items)
          .map(item => {
            return (
              <Accordion key={item.question}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4>{item.question}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{item.answer}</p>
                </AccordionDetails>
              </Accordion>
            );
          })
      : gridItems &&
        gridItems
          .filter(item => item.category === filter)
          .map(item => {
            return (
              <Accordion key={item.question}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4>{item.question}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{item.answer}</p>
                </AccordionDetails>
              </Accordion>
            );
          })}
  </div>
);

FaqGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.string
    })
  )
};

export default FaqGrid;
